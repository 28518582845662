<template>
  <div class="settings" style="background-color: #f4f5f7; height: auto;" >
    <app-redirect/>
  </div>
</template>

<script>


  import appRedirect from "@/pages/components/appRedirect";


  export default {
    name: "src-pages-body-workstation-portfolio-v1_mobile",
    components: {
      appRedirect,
    },
    data () {
      return {

      }
    },






  }
</script>
